<template>
  <div>
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="文章标题" prop="tittle" >
        <el-input v-model="addForm.tittle" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item label="显示内容" prop="tittlcontent">
        <el-input v-model="addForm.tittlcontent" type="textarea" placeholder="内容" rows="3"></el-input>
      </el-form-item>

      <el-form-item label="文章标签">
        <el-input v-model="addForm.addtag" placeholder="新增标签" style="width: 200px"></el-input>
        <el-button icon="el-icon-edit" type="primary" plain @click="onaddtag">新增标签</el-button>
        <el-select v-model="addForm.tags" multiple filterable placeholder="请选择标签">
          <el-option
              v-for="item in options" :key="item.Id" :label="item.Tagname" :value="item.Id">
          </el-option>
        </el-select>
        <el-tag v-for="tag in addForm.tags" :key="tag" closable @close="closetags(tag)">{{tag}}</el-tag>
      </el-form-item>

      <el-form-item label="文章分类" >
        <el-input v-model="addForm.addclassify" placeholder="新增分类" style="width: 200px"></el-input>
        <el-button icon="el-icon-edit" type="primary" plain @click="onaaddclassify">新增分类</el-button>
        <el-select v-model="addForm.classify" multiple filterable placeholder="请选择分类">
          <el-option
              v-for="item in optionsclassify" :key="item.Id" :label="item.Classifyname" :value="item.Id">
          </el-option>
        </el-select>
        <el-tag v-for="tag in addForm.classify" :key="tag" closable @close="closeclassify(tag)">{{tag}}</el-tag>
      </el-form-item>

      <el-form-item label="文章类型">
        <el-radio v-model="addForm.blogtype" label="原创">原创</el-radio>
        <el-radio v-model="addForm.blogtype" label="转载">转载</el-radio>
      </el-form-item>

      <el-form-item label="发布类型">
        <el-radio v-model="addForm.blogreleasetype" label="公开">公开</el-radio>
        <el-radio v-model="addForm.blogreleasetype" label="私密">私密</el-radio>
      </el-form-item>
      <vue-ueditor-wrap v-model="content" :config="myConfig" editor-id="editor-demo-01"></vue-ueditor-wrap>
      <el-form-item style="margin-top: 20px">
        <el-button type="primary" @click="onSubmit" :disabled="isdisabled">立即创建</el-button>
        <el-button type="danger">保存草稿</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import {
  GetBlogClassifyBaseList,
  GetBlogTagsBaseList,
  PostAddBlog,
  PostBlogClassifyBaseAdd,
  PostBlogTagsBaseAdd
} from "@/network/blog";
import {formatDate} from "element-ui";
export default {
  name: "Barticle",
  components:{VueUeditorWrap},
  methods:{
    addlevel(val){
      this.addForm.levelid =val
    },
    closetags(tag){
        this.addForm.tags.splice(this.addForm.tags.indexOf(tag), 1);
    },
    closeclassify(tag){
      this.addForm.classify.splice(this.addForm.classify.indexOf(tag), 1);
    },
    onSubmit() {
      this.$refs.addFormRef.validate(call=> {
        if (!call) return
        this.isdisabled =true
        let frmDate = new FormData();
        frmDate.append('tittle', this.addForm.tittle);
        frmDate.append('tittlcontent', this.addForm.tittlcontent);
        frmDate.append('tags', this.addForm.tags);
        frmDate.append('classify', this.addForm.classify);
        frmDate.append('blogtype', this.addForm.blogtype);
        frmDate.append('blogreleasetype', this.addForm.blogreleasetype);
        frmDate.append('content', this.content);
        PostAddBlog(this.$store.state.token,frmDate).then(res => {
          if (res.isadd ===true){
            this.$message({
              message: '发布文章成功！',
              type: 'success'
            })
            this.$refs.addFormRef.resetFields()
            this.content =''
          }
        }).catch(err => {
          console.log(err);
        })
        this.isdisabled =false
      })
    },
    //初始化加载数据
    initdata(){
      GetBlogTagsBaseList(this.$store.state.token).then(res => {
        this.options =res.data
      }).catch(err => {
        console.log(err);
      })

      GetBlogClassifyBaseList(this.$store.state.token).then(res => {
        this.optionsclassify =res.data
      }).catch(err => {
        console.log(err);
      })

    },
    //新增标签
    onaddtag(){
      if (this.addForm.addtag===''){
        this.$message({
          message: '标签不能为空！',
          type: 'error'
        })
      }else{
        let fm = new FormData()
        fm.append('tagname',this.addForm.addtag)
        PostBlogTagsBaseAdd(this.$store.state.token,fm).then(res => {
          if (res.isadd===true){
            GetBlogTagsBaseList(this.$store.state.token).then(ress => {
              this.options =ress.data
              this.$message({
                message: '添加成功！',
                type: 'success'
              })
            }).catch(errr => {
              console.log(errr);
            })
          }else{
            this.$message({
              message: '添加失败！',
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    //新增分类
    onaaddclassify(){
      if (this.addForm.addclassify===''){
        this.$message({
          message: '分类不能为空！',
          type: 'error'
        })
      }else{
        let fm = new FormData()
        fm.append('classifyname',this.addForm.addclassify)
        PostBlogClassifyBaseAdd(this.$store.state.token,fm).then(res => {
          if (res.isadd===true){
            GetBlogClassifyBaseList(this.$store.state.token).then(ress => {
              this.optionsclassify =ress.data
              this.$message({
                message: '添加成功！',
                type: 'success'
              })
            }).catch(errr => {
              console.log(errr);
            })
          }else{
            this.$message({
              message: '添加失败！',
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    }
  },
  data(){
    return {
      myConfig: {
        // 上传功能,服务器接口地址
        serverUrl: 'http://122.51.221.44:9003/api/UEditor/Upload',
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/UEditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 工具栏是否可以浮动
        autoFloatEnabled: false,
        // 初始容器高度
        initialFrameHeight: 540,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: true
      },
      content: '',
      addForm:{
        tittle:'',
        tittlcontent:'',
        tags:'',
        addtag:'',
        classify:'',
        addclassify:'',
        blogtype:'原创',
        blogreleasetype:'公开',
      },
      //新增用户字段验证规则
      addFormRules:{
        tittle: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        tittlcontent: [
          { required: true, message: '文章显示内容', trigger: 'blur' }
        ]
      },
      options:[],
      optionsclassify:[],
      isdisabled:false,
    }
  },created() {
    this.initdata()
  },
}
</script>

<style scoped>
.el-tag{
  margin-left: 5px;
}
.el-button{
  margin-left: 5px;
  margin-right: 5px;
}
</style>
